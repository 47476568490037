$(function () {
    $(document).on("click", '.prev-scr-btn', function () {
        // この要素から次のdiv要素を左スクロール
        let target = $(this).next().next();
        let scrollLeft = target.scrollLeft() - 600;
        if (scrollLeft < 0) {
            scrollLeft = 0;
        }
        target.animate({scrollLeft: scrollLeft}, 500);
    });

    $(document).on("click", '.next-scr-btn', function () {
        // この要素から次のdiv要素を右スクロール
        let target = $(this).next();
        let scrollLeft = target.scrollLeft() + 600;
        target.animate({scrollLeft: scrollLeft}, 500);
    });
});
